import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import VideoStepBar from "./VideoStepBar";
import { AiOutlineClose } from "react-icons/ai";
import { MdAdd } from "react-icons/md";
import { FaImage } from "react-icons/fa6";
import { useDropzone } from "react-dropzone";
import { MdSmartDisplay } from "react-icons/md";
import {
  isUploadingStatus,
  uploadFile,
  uploadFileInchunk,
} from "../../../utils/fileUpload";
import axios from "../../../api/axios";
import { notifyDanger, notifySuccess } from "../../../actions/customFn";
import { useDispatch, useSelector } from "react-redux";
import { setVideoData } from "../../../slice/batchSlice";
import { useParams } from "react-router-dom";
import {
  setNotificationOpen,
  setVideoNotification,
} from "../../../slice/notificationSlice";
import Loader from "../../../utils/Loader";
import { asyncSendNotification } from "../../../actions/notificationApi";
import ReactQuill from "react-quill";

const UploadVideoModal = ({
  modalShow,
  setShowModal,
  setIsUpdate,
  isEdit,
  videoData,
  moduleTitle,
}) => {
  const [progress, setProgress] = useState(25);
  const [step, setStep] = useState(1);

  const video_data = useSelector((state) => state.batchData.video_data);
  const { id } = useParams();

  const stepOneErr = {};
  const dispatch = useDispatch();
  const [stepOneErrors, setStepOneErrors] = useState({});

  const stepTwoErr = {};
  const [stepTwoErrors, setStepTwoErrors] = useState({});

  const stepThreeErr = {};
  const [stepThreeError, setStepThreeError] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const video_notification = useSelector(
    (state) => state.notificationData.video_notification
  );

  const [uploadData, setUploadData] = useState({
    title: "",
    description: "",
    order: "",
    copyurl: "",
  });

  const [fileData, setFileData] = useState({
    img: "",
    video: "",
  });

  const [filePreview, setFilePreview] = useState({
    img: "",
    video: "",
  });

  const [thumbnail, setThumbnail] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);

  const handleDataChange = (e) => {
    const { name, value } = e.target;
    setUploadData({ ...uploadData, [name]: value });
  };

  const initialState = () => {
    setFileData({
      img: "",
      video: "",
    });
    setFilePreview({
      img: "",
      video: "",
    });
    setUploadData({
      title: "",
      description: "",
      order: "",
      copyurl: "",
    });
    setProgress(25);
    setStep(1);
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      setFileData({ ...fileData, [name]: files[0] });
      setFilePreview({ ...filePreview, [name]: URL.createObjectURL(files[0]) });
    }
  };

  const modules = {
    toolbar: [
      ["bold", "italic"], // Bold and Italic buttons
      [{ list: "ordered" }, { list: "bullet" }], // Ordered and bullet list
    ],
  };

  const onDrop = (acceptedFiles) => {
    setUploadData({ ...uploadData, copyurl: "" });
    setFileData({ ...fileData, video: acceptedFiles[0] });
    setFilePreview({
      ...filePreview,
      video: URL.createObjectURL(acceptedFiles[0]),
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "video/*": [],
    },
  });

  const stepOneValidation = () => {
    if (uploadData.title.length === 0) {
      stepOneErr.title = "title is required!";
    }
    if (uploadData.description.length === 0) {
      stepOneErr.description = "description is required!";
    }
    setStepOneErrors(stepOneErr);
  };

  const handleStepOne = () => {
    stepOneValidation();
    if (Object.keys(stepOneErr).length === 0) {
      setStep(2);
      setProgress(50);
    }
  };

  const stepTwoValidation = () => {
    if (uploadData.order.length === 0) {
      stepTwoErr.order = "order is required!";
    }
    if (filePreview.img === "") {
      stepTwoErr.img = "thumbnail img is required!";
    }
    setStepTwoErrors(stepTwoErr);
  };

  const handleStepTwo = () => {
    stepTwoValidation();
    if (Object.keys(stepTwoErr).length === 0) {
      setStep(3);
      setProgress(100);
    }
  };

  const stepThreeValidation = () => {
    if (uploadData.copyurl === "" && filePreview.video === "") {
      stepThreeErr.fileErr = "please provide either video file or url";
    }
    setStepThreeError(stepThreeErr);
  };

  const handleStepThree = () => {
    stepThreeValidation();
    if (Object.keys(stepThreeErr).length === 0) {
      setStep(4);
    }
  };

  const getFileExtension = (filename) => {
    const parts = filename.split(".");
    return parts[parts.length - 1].toLowerCase();
  };

  const handleVideoClick = () => {
    if (isUploadingStatus) {
      notifyDanger("cannot upload multiple file at once!");
    } else {
      dispatch(setVideoData({ ...uploadData, ...fileData }));
    }
  };

  const handleVideoSubmit = async () => {
    let video = null;

    if (video_data.video !== "") {
      video = await uploadFileInchunk(
        video_data.video,
        video_data.title,
        true,
        dispatch,
        setVideoNotification
      );
    }
    const formData = new FormData();
    formData.append("moduleId", id);
    formData.append("title", video_data.title);
    formData.append("content", video_data.description);

    if (video) {
      formData.append("video", video);
    }

    if (uploadData.copyurl !== "") {
      formData.append("copyUrl", uploadData.copyurl);
    }
    if (video_data.img !== "") {
      formData.append("thumbnail", video_data.img);
    }
    formData.append("order", video_data.order);
    formData.append("isLive", false);

    const url = "/batch/upload-video";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .put(url, formData, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsUpdate((p) => !p);
        dispatch(setVideoData({}));
        setIsUploading(false);
        setShowModal(false);
        if (video) {
          const studentIds = studentList.enroll_students.map((doc) => {
            return doc._id;
          });
          const data = {
            recipientArr: studentIds,
            messages: {
              notification_msg: `New Video Uploaded in <b>${moduleTitle}</b> Module.`,
              date: new Date(),
              notification_type: "program",
            },
          };
          dispatch(asyncSendNotification(data));
        }
      })
      .catch((err) => {
        console.log(err.response);
        dispatch(setVideoData({}));
        setIsUploading(false);
        setShowModal(false);
        setIsUpdate((p) => !p);
        notifyDanger(err.response.message || "Some error Occured");
      });
  };

  useEffect(() => {
    if (video_data && Object.keys(video_data).length > 0) {
      if (fileData.video !== "") {
        setShowModal(false);
        dispatch(setNotificationOpen(true));
      } else {
        setIsUploading(true);
      }
      initialState();
      if (!isUploadingStatus) {
        if (isEdit) {
          handleUpdateVideoDetails();
        }
        if (isEdit === false) {
          handleVideoSubmit();
        }
      }
    }
  }, [video_data]);

  useEffect(() => {
    if (isEdit) {
      setThumbnail(videoData.thumbnail ? videoData.thumbnail : "");
      setVideoUrl(videoData.videoUrl ? videoData.videoUrl : "");
      setFilePreview({
        img: videoData.thumbnail ? videoData.thumbnail : "",
        video: videoData.videoUrl ? videoData.videoUrl : "",
      });

      setUploadData({
        title: videoData.title,
        description: videoData.content,
        order: videoData.order,
        copyurl: "",
      });
      setProgress(25);
      setStep(1);
    }
  }, [videoData]);

  useEffect(() => {
    const url = `/batch/getstudent-by-moduleId/${id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setStudentList(data);
      })
      .catch((err) => {
        console.log(err.response || err);
      });
  }, [id]);

  const getFileUrl = (filename) => {
    if (filename) {
      const fileIndex = filename.lastIndexOf("/") + 1;
      const imageUrl = filename.substr(fileIndex);
      return imageUrl;
    } else {
      return null;
    }
  };

  const handleUpdateVideoDetails = async () => {
    // lessonId, content, title, isLive, order, videoUrl , meetlink

    let video = null;
    if (fileData.video !== "") {
      video = await uploadFileInchunk(
        video_data.video,
        video_data.title,
        true,
        dispatch,
        setVideoNotification
      );
    }
    const formData = new FormData();
    formData.append("lessonId", videoData._id);
    formData.append("title", video_data.title);
    formData.append("content", video_data.description);
    if (videoUrl) {
      formData.append("videoUrl", videoUrl);
    }
    if (video_data.copyurl) {
      formData.append("copyUrl", video_data.copyurl);
    }

    if (video) {
      formData.append("video", video);
    }
    if (fileData.img !== "") {
      formData.append("image", fileData.img);
    }
    if (thumbnail) {
      formData.append("thumbnail", thumbnail);
    }
    // formData.append("meetlink", videoData.meetlink);
    formData.append("order", video_data.order);
    formData.append("isLive", false);

    const url = "/batch/update-module-video";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .put(url, formData, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsUpdate((p) => !p);
        setIsUploading(false);
        dispatch(setVideoData({}));
        setShowModal(false);
        if (video) {
          const studentIds = studentList.enroll_students.map((doc) => {
            return doc._id;
          });
          const data = {
            recipientArr: studentIds,
            messages: {
              notification_msg: `New Video Uploaded in <b>${moduleTitle}</b> Module.`,
              date: new Date(),
              notification_type: "program",
            },
          };
          dispatch(asyncSendNotification(data));
        }
      })
      .catch((err) => {
        console.log(err);
        setIsUploading(false);
        dispatch(setVideoData({}));
        setShowModal(false);
        setIsUpdate((p) => !p);
        notifyDanger(err?.response?.message || "Some error Occured");
      });
  };

  return (
    <Modal
      show={modalShow}
      className="bootstrap-modal-custom upload-video-modal"
      onHide={() => setShowModal(false)}
      size="lg"
      centered
    >
      <Modal.Body>
        {isUploading ? (
          <Loader Height={"50vh"} />
        ) : (
          <div className="upload-video-modal-body">
            <AiOutlineClose
              className="modal-close-btn"
              onClick={() => setShowModal(false)}
            />

            {step < 4 && (
              <div className="upload-progress-bar">
                <div className="upload-progress-bar-div">
                  <VideoStepBar progress={progress} setProgress={setProgress} />
                </div>
              </div>
            )}

            {step === 1 && (
              <div className="upload-video-modal-form">
                <div className="upload-video-modal-inp">
                  <label>Title of Your Video</label>
                  <input
                    placeholder="Enter video title"
                    type="text"
                    name="title"
                    value={uploadData.title}
                    onChange={handleDataChange}
                  />
                  {stepOneErrors.title && (
                    <div className="form_error_div">{stepOneErrors.title}</div>
                  )}
                </div>
                <div className="upload-video-modal-inp">
                  <label>Description of Your Video</label>
                  {/* <textarea
                    rows={"3"}
                    placeholder="Enter video description"
                    name="description"
                    value={uploadData.description}
                    onChange={handleDataChange}
                  /> */}

                  <ReactQuill
                    theme="snow"
                    value={uploadData.description}
                    onChange={(e) =>
                      setUploadData({ ...uploadData, description: e })
                    }
                    setUploadData
                    modules={modules}
                  />

                  {stepOneErrors.description && (
                    <div className="form_error_div">
                      {stepOneErrors.description}
                    </div>
                  )}
                </div>
                <div className="upload-video-modal-submit">
                  <button onClick={() => handleStepOne()}>Next</button>
                </div>
              </div>
            )}

            {step === 2 && (
              <div className="upload-video-modal-form">
                <div className="upload-video-modal-inp">
                  <label>Video Sequence</label>
                  <input
                    placeholder="Enter video sequence"
                    type="number"
                    value={uploadData.order}
                    name="order"
                    onChange={handleDataChange}
                  />
                  {stepTwoErrors.order && (
                    <div className="form_error_div">{stepTwoErrors.order}</div>
                  )}
                </div>
                <div className="upload-video-modal-inp">
                  <label>Upload Video Thumbnail</label>
                  {/* <h6>No thumbnail uploaded</h6> */}
                  {fileData?.img?.name && (
                    <div className="upload-video-modal-selectfile">
                      <div className="upload-video-selectfile-div">
                        <p>
                          <span>{fileData?.img?.name}</span>{" "}
                          {/* <AiOutlineClose  /> */}
                        </p>
                        <text>
                          <FaImage />
                        </text>
                      </div>
                    </div>
                  )}
                  {!fileData.img.name && isEdit && filePreview?.img && (
                    <div className="upload-video-modal-selectfile">
                      <div className="upload-video-selectfile-div">
                        <p>
                          <span>{getFileUrl(filePreview.img)}</span>{" "}
                        </p>
                        <text>
                          <FaImage />
                        </text>
                      </div>
                    </div>
                  )}
                  <div className="text-center">
                    <label
                      htmlFor="thumbnailId"
                      className="upload-video-thumbnail-label"
                    >
                      <MdAdd />
                      <span>Add thumbnail</span>
                    </label>
                  </div>
                  <input
                    id="thumbnailId"
                    type="file"
                    accept="image/*"
                    className="d-none"
                    onChange={handleFileChange}
                    name="img"
                  />
                  {stepTwoErrors.img && (
                    <div className="form_error_div">{stepTwoErrors.img}</div>
                  )}
                </div>
                <div className="upload-video-modal-submit">
                  <button
                    onClick={() => {
                      setStep(1);
                      setProgress(25);
                    }}
                  >
                    Previous
                  </button>
                  <button onClick={() => handleStepTwo()}>Next</button>
                </div>
              </div>
            )}

            {step === 3 && (
              <div className="upload-video-modal-form">
                <div className="upload-video-modal-inp">
                  <label>Upload Video</label>
                  {filePreview.video !== "" ? (
                    <div className="dropzone">
                      <div className="upload-video-modal-file-select">
                        <div className="upload-video-modal-file-txt">
                          <MdSmartDisplay />{" "}
                          <span>
                            {isEdit
                              ? getFileUrl(filePreview.video)
                              : fileData.video.name}
                          </span>
                        </div>
                        <div className="upload-video-modal-file-close">
                          <AiOutlineClose
                            onClick={() => {
                              setFileData({ ...fileData, video: "" });
                              setFilePreview({ ...filePreview, video: "" });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      {...getRootProps()}
                      className={`dropzone  ${isDragActive ? "active" : ""}`}
                    >
                      <input {...getInputProps()} />
                      <button>Browse Your Device</button>
                      <p>or</p>
                      <p>Drag & Drop here</p>
                    </div>
                  )}

                  <div className="upload-video-modal-or">
                    <span></span>
                    <b>Or</b>
                    <span></span>
                  </div>
                  <input
                    placeholder="Paste the Video URL "
                    type="text"
                    value={uploadData.copyurl}
                    name="copyurl"
                    onChange={(e) => {
                      setFileData({ ...fileData, video: "" });
                      setFilePreview({ ...filePreview, video: "" });
                      handleDataChange(e);
                    }}
                  />
                </div>
                {stepThreeError.fileErr && (
                  <div className="form_error_div">{stepThreeError.fileErr}</div>
                )}

                <div className="upload-video-modal-submit">
                  <button onClick={() => setStep(2)}>Previous</button>
                  <button onClick={() => handleStepThree()}>Next</button>
                </div>
              </div>
            )}

            {step === 4 && (
              <div className="upload-video-modal-form">
                <div className="upload-video-preview">
                  <div className="upload-video-preview-title">
                    <h3>{uploadData.title}</h3>
                    <h3>No. {uploadData.order}</h3>
                  </div>
                  <div className="upload-video-preview-desc scrollbar-template">
                    <div
                      className="upload-video-preview-desc-div"
                      dangerouslySetInnerHTML={{
                        __html: uploadData.description,
                      }}
                    ></div>
                  </div>
                  <div className="upload-video-preview-file">
                    <img src={filePreview.img} alt="" />
                    <video controls="true">
                      <source
                        src={filePreview.video || uploadData.copyurl}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
                <div className="upload-video-modal-submit">
                  <button
                    onClick={() => {
                      setStep(3);
                      setProgress(100);
                    }}
                  >
                    Previous
                  </button>
                  <button
                    onClick={() => {
                      handleVideoClick();
                    }}
                  >
                    upload
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default UploadVideoModal;
