import moment from "moment";
import React from "react";

const DUMMY_DATA = [
  {
    title: "math's study day 1 basics class",
    description:
      "Geometry is a branch of mathematics that deals with the study of shapes, sizes, properties of space, and the relationships between various elements. ",
    batch_name: "DS15NOV4PM2023",
    module_name: "Test Module",
    date: "20/04/2024",
  },
  {
    title: "math's study day 1 basics class",
    description:
      "Geometry is a branch of mathematics that deals with the study of shapes, sizes, properties of space, and the relationships between various elements. ",
    batch_name: "DS15NOV4PM2023",
    module_name: "Test Module",
    date: "14/06/2024",
  },
];

const DashboardScheduleList = ({ allSchedules }) => {
  return (
    <div className="dashboard_schedulelist_container scrollbar-template">
      <div className="dashboard_schedulelist_heading"></div>
      <div className="dashboard_schedulelist_list">
        {allSchedules.length > 0 &&
          allSchedules.map((batch) => {
            return batch.modules.map((module) => {
              return module.lessons.map((details, i) => {
                return (
                  <DashboardScheduleItems
                    data={details}
                    i={i}
                    batch_name={batch.custom_name || batch.batch_name}
                    meetlink={batch.meetlink}
                    module_title={module.title}
                  />
                );
              });
            });
          })}
      </div>
    </div>
  );
};

export default DashboardScheduleList;

const DashboardScheduleItems = ({ data, i, batch_name, module_title }) => {
  return (
    <div className="dashboard_schedulelist_item" key={i}>
      <div className="dashboard_schedulelist_title">
        <h3>{batch_name}</h3>
        <h4>{data.title}</h4>
      </div>
      <div className="dashboard_schedulelist_desc">
        <div
          className="dashboard_schedulelist_div"
          dangerouslySetInnerHTML={{ __html: data.content }}
        ></div>
        <div className="dashboard_schedulelist_date">
          <span>{module_title}</span>
          <b>{moment(data.schedule_time).format("DD MMM YYYY LT")}</b>
        </div>
      </div>
    </div>
  );
};
